import { useState, useRef } from "react";
import cn from "classnames";
import { useOnClickOutside } from "hooks/useOnClickOutside";
import styles from "./Dropdown.module.scss";
import Icon from "../Icon";

const Dropdown = ({ className, value, setValue, options }) => {
  const [visible, setVisible] = useState(false);
  const ref = useRef(null);

  useOnClickOutside(ref, () => {
    setVisible(false);
  });

  const handleClick = (value) => {
    setValue(value);
    setVisible(false);
  };

  return (
    <div
      className={cn(styles.dropdown, className, { [styles.active]: visible })}
      ref={ref}
    >
      <div className={styles.head} onClick={() => setVisible(!visible)}>
        <div className={styles.selection}>{value}</div>
        <div className={styles.arrow}>
          <Icon name="arrow-bottom" size="10" />
        </div>
      </div>
      <div className={styles.body}>
        {options.map((x, index) => (
          <div
            className={cn(styles.option, {
              [styles.selectioned]: x === value,
            })}
            onClick={() => handleClick(x, index)}
            key={index}
          >
            {x}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Dropdown;
