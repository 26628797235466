import { useCallback, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import cn from "classnames";
import { useOnClickOutside } from "hooks/useOnClickOutside";
import styles from "./Modal.module.scss";
import Icon from "../Icon";

const Modal = ({
  outerClassName,
  containerClassName,
  visible,
  onClose,
  children,
}) => {
  const escFunction = useCallback(
    (e) => {
      if (e.keyCode === 27) {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  const scrollRef = useRef(null);
  const modalRef = useRef(null);

  useOnClickOutside(modalRef, () => {
    onClose();
  });

  useEffect(() => {
    visible ? disableBodyScroll(scrollRef) : enableBodyScroll(scrollRef);
  }, [visible]);

  return createPortal(
    visible && (
      <div className={styles.modal} ref={scrollRef}>
        <div className={cn(styles.outer, outerClassName)}>
          <div
            className={cn(styles.container, containerClassName)}
            ref={modalRef}
          >
            {children}
            <button className={styles.close} onClick={onClose}>
              <Icon name="close" size="14" />
            </button>
          </div>
        </div>
      </div>
    ),
    document.body
  );
};

export default Modal;
