import { useState, useEffect, createContext, useContext } from "react";

const storageKey = "darkMode";
const classNameDark = "dark-mode";
const classNameLight = "light-mode";

const setClassOnDocumentBody = (darkMode) => {
  document.body.classList.add(darkMode ? classNameDark : classNameLight);
  document.body.classList.remove(darkMode ? classNameLight : classNameDark);
};

const getIsDarkMode = () => {
  const isDarkMode = localStorage.getItem(storageKey);
  if (isDarkMode === "true") return true;
  if (isDarkMode === "false") return false;
  if (window.matchMedia)
    return window.matchMedia("(prefers-color-scheme: dark)").matches;
  return true;
};

const DarkModeContext = createContext(getIsDarkMode());

export const DarkModeProvider = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState(getIsDarkMode());

  useEffect(() => {
    localStorage.setItem(storageKey, isDarkMode);
    setClassOnDocumentBody(isDarkMode);
  }, [isDarkMode]);

  const value = {
    value: isDarkMode,
    enable: () => setIsDarkMode(true),
    disable: () => setIsDarkMode(false),
    toggle: () => setIsDarkMode((current) => !current),
  };

  return (
    <DarkModeContext.Provider value={value}>
      {children}
    </DarkModeContext.Provider>
  );
};

export const useDarkMode = () => {
  return useContext(DarkModeContext);
};

export default useDarkMode;
